@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button {
    letter-spacing: inherit;
  }
  html {
    @apply font-normal;
    @apply text-black;
    @apply text-sm;
  }
  body {
    @apply bg-white;
  }
  * {
    @apply no-scrollbar;
  }
  ::selection {
    @apply bg-main text-white;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@layer components {
  .typo-md {
    @apply ar text-mdSM lg:text-md;
  }
  /* .linkNavigation {
    @apply ar text-gray hover:text-black;
  } */
  /* ! Need opactity to use it inside PortableText without passing a theme */
  .linkNavigation {
    @apply ar opacity-50 hover:opacity-100;
  }
  .linkReverseNavigation {
    @apply ar text-black hover:text-gray;
  }
  .linkUnderlineNavigation {
    @apply ar underline-offset-3 hover:underline;
  }
  .ar {
    @apply duration-300 ease-in-out;
  }
  .flex-center {
    @apply flex items-center justify-center;
  }

  .portableText {
    ol {
      list-style-type: decimal;
    }
    ul {
      list-style: circle outside;
    }
  }

  /* Form input fallback theme: main */
  .autofill-text:-webkit-autofill {
    -webkit-text-fill-color: white;
  }
  .autofill-input-main {
    @apply autofill-text;
    @apply autofill:shadow-[inset_0_0_0px_1000px_#040AB4];
  }
}

* {
  font-variant-ligatures: none;
}

html {
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

/* Embla Carousel */
.embla {
  height: 100%;
}
.embla__viewport {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.embla__container {
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
}
.embla__slide {
  height: 100%;
  min-width: 0;
  flex: 0 0 333.33px;
  /* 333.33 px width for aspect ratio 2/3 product */
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}
.embla__dot {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #A5A5A5;
}
.embla__dot.selected {
  background-color: #040AB4 !important;
}

